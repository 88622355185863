.scrollable-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-container::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  
  .scrollable-container:hover::-webkit-scrollbar-thumb {
    background: #888888;
  }
  
  .scrollable-container {
    scrollbar-width: none; 
  }
