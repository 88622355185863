

.swiper-button-prev {
  left: 12px;
}

.swiper-button-next {
  right: 12px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 15px; /* Adjust arrow size */
  font-weight: bolder
}
