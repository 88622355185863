@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'OrtankText-Bold';
  src: url('fonts/OrtankText-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'OrtankText-Light';
  src: url('fonts/OrtankText-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'OrtankText-Regular';
  src: url('fonts/OrtankText-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'OrtankText-SemiBold';
  src: url('fonts/OrtankText-SemiBold.ttf') format('ttf');
}
